<template>
  <v-dialog v-model="show" persistent @keydown.esc="close()" scrollable >
    <v-card v-if="roles" class="px-1 py-2">
      <v-card-title class="d-flex align-center">
        <div class="subtitle-1">
          <v-btn outlined v-if="showCompare" @click="cancelCompare"><v-icon left>arrow_back</v-icon>Back</v-btn>
          <template v-else-if="compareList.length">
            <v-btn class="mr-3" color="primary" outlined @click="cancelCompare">Cancel</v-btn>
            <v-btn class="mr-3" color="primary" @click="doCompare">Compare</v-btn>
          </template>
          <template v-else>
            Audit History ({{ lifecycle_name }} Workflow)
          </template>
        </div>
        <v-spacer />
        <v-switch v-if="showCompare" v-model="compareHighlightChanges" label="Highlight Changes" class="mr-4"></v-switch>
        <v-btn
          v-if="!showCompare"
          class="mr-4"
          color="primary"
          outlined
          @click="exportToCSV">
          <v-icon left>download</v-icon>Export
        </v-btn>
        <v-menu v-if="!showCompare && tabs[tab].name === 'actions'"
          offset-y
          :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              v-bind="attrs"
              v-on="on">
              <v-icon left>{{ includedActivityTypes.length !== activityTypes.length ? 'filter_alt' : 'filter_alt_off' }}</v-icon>
              Filter
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="t in activityTypes" :key="t">
              <v-list-item-content class="pl-2">
                <v-checkbox :label="t"
                  @click.stop
                  :input-value="includedActivityTypes.includes(t)"
                  @change="toggleItemInList(t, includedActivityTypes)">
                </v-checkbox>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon large class="btn-background" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text dense style="height: calc(90vh - 90px)">
        <v-fab-transition>
          <DocumentVersionCompare
            class="pt-6 px-2"
            style="height: 100%;"
            v-if="showCompare"
            :compareList="compareList"
            :highlightChanges="compareHighlightChanges"
            :hideCompareVersion="compareHideCompareVersion"
            :multiDoc="compareMultiDoc"
            @openDocument="openDocument"
            @copyDocument="$emit('copyDocument', $event)">
          </DocumentVersionCompare>
        </v-fab-transition>
        <template v-if="!showCompare">
          <v-tabs fixed-tabs :background-color="$vuetify.theme.dark ? '' : 'grey lighten-2'" v-model="tab">
            <v-tab v-for="t in tabs" :key="t.name"> {{ t.description }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" style="max-height: calc(90vh - 160px); overflow: auto;">
            <v-tab-item v-for="t in tabs" :key="t.name">
              <v-timeline v-if="t.name === 'actions'"
                dense class="px-4">
                <template v-for="(t, ti) in timeline" >
                  <v-timeline-item :color="t.colour" :key="'tl' + ti">
                    <template v-slot:opposite>
                      <span class="font-weight-bold">{{ t.date_range }}</span>
                    </template>
                    <v-card>
                      <v-card-text class="d-flex align-center justify-space-between" :style="t.expandable ? 'cursor: pointer' : ''" @click="t.expanded = !t.expanded">
                        <div class="font-weight-bold d-flex align-center">
                          <v-checkbox
                            @click.stop
                            class="ma-0 mr-2"
                            value
                            :input-value="compareActivities.includes(t.document_activity_id)"
                            @change="toggleItemInList(t.document_activity_id, compareActivities)"></v-checkbox>
                          
                          {{ t.actions.length > 1 ? t.actions.length : '' }} {{ t.actions.length > 1 ? utils.pluralise(t.activity_type) : t.activity_type }}
                          by {{ t.user_name }}
                          <template v-if="t.new_status">
                            <v-icon class="mx-3" small>arrow_forward</v-icon>
                            <v-chip
                              small
                              class="status-chip"
                              label
                              :color="t.new_status_colour"
                              >{{ t.new_status }}</v-chip
                            >
                          </template>
                        </div>
                        <div class="d-flex align-center">
                          <v-progress-circular
                            :aria-label="`${t.date_range} score`"
                            v-if="
                              showScores &&
                              t.score !== undefined &&
                              t.score !== null
                            "
                            :rotate="90"
                            :size="30"
                            :width="3"
                            :value="t.score"
                            :color="t.scoreColour"
                            class="mr-2"
                            >{{ t.score }}
                          </v-progress-circular>
                          <v-btn icon @click.stop="viewVersion(t)"><v-icon>visibility</v-icon></v-btn>
                          <v-icon v-if="t.expandable" :class="[ 'ml-2 toggleExpand', t.expanded ? 'expanded' : '' ]">expand_more</v-icon>
                          <div v-else style="width: 32px"></div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                  <v-slide-y-transition>
                    <div v-if="t.expandable && t.expanded" :key="'tld' + ti" class="pb-3">
                      <v-timeline-item v-for="(a, ai) in t.actions" :key="`tl_${ti}_tla_${ai}`"
                        :color="t.colour" small class="pb-3">
                        <template v-slot:opposite>
                          <span>{{ a.activity_date }}</span>
                        </template>
                        <v-card class="mr-8">
                          <v-card-text class="d-flex justify-space-between" :style="a.hasDetails ? 'cursor: pointer' : ''" @click="a.showDetails = !a.showDetails">
                            <div :class="['d-flex', 'align-center' ]">
                              <v-checkbox
                                @click.stop
                                class="ma-0 mr-2"
                                value
                                :input-value="compareActivities.includes(a.document_activity_id)"
                                @change="toggleItemInList(a.document_activity_id, compareActivities)"></v-checkbox>
                              {{ a.activity_description }}
                              <template v-if="t.new_status">
                                <v-icon class="mx-3" small>arrow_forward</v-icon>
                                <v-chip
                                  small
                                  class="status-chip"
                                  label
                                  :color="t.new_status_colour"
                                  >{{ t.new_status }}</v-chip
                                >
                              </template>
                            </div>
                            <div>
                              <v-progress-circular
                                :aria-label="`${t.date_range} score`"
                                v-if="
                                  showScores &&                                
                                  t.score !== undefined &&
                                  t.score !== null
                                "
                                :rotate="90"
                                :size="30"
                                :width="3"
                                :value="t.score"
                                :color="t.scoreColour"
                                :class="a.hasDetails ? 'mr-3' : 'mr-11'"
                                >{{ t.score }}
                              </v-progress-circular>
                              <v-icon v-if="a.hasDetails" :class="[ 'ml-2 toggleExpand', a.showDetails ? 'expanded' : '' ]">expand_more</v-icon>
                            </div>
                          </v-card-text>
                          <v-slide-y-transition>
                            <v-card-text v-if="a.hasDetails && a.showDetails" class="py-0">
                              <v-alert
                                type="info"
                                border="top"
                                colored-border
                                icon="edit"
                                class="body-2">
                                <DocumentActivityDetails
                                  :doc_id="doc_id"
                                  :activity_id="a.document_activity_id">
                                </DocumentActivityDetails>
                              </v-alert>
                            </v-card-text>
                          </v-slide-y-transition>
                          <v-card-text v-if="a.tasksAffected.length" class="pt-0 pl-12">
                            <v-alert
                              border="left"
                              colored-border
                              type="info"
                              dense
                              class="mb-0 body-2"
                              v-for="(ta, tai) in a.tasksAffected"
                              :key="'a2_' + a.document_activity_id + 'ta' + tai"
                            >
                              {{ ta.effect }} TASK
                              <v-chip small outlined label color="orange">{{
                                ta.type
                              }}</v-chip>
                              Due: {{ ta.due_date }};
                              {{
                                ta.assigned_user_name
                                  ? "Assigned to: " + ta.assigned_user_name
                                  : "UNNASIGNED"
                              }}
                            </v-alert>
                          </v-card-text>
                          <v-card-text v-if="a.notifications.length" class="pt-0 pl-12">
                            <v-alert
                              border="left"
                              colored-border
                              type="info"
                              dense
                              class="my-0 body-2"
                              v-for="(ta, tai) in a.notifications"
                              :key="'a2_' + a.document_activity_id + 'na' + tai"
                            >
                              send NOTIFICATION
                              <v-chip small outlined label>{{
                                ta.type
                              }}</v-chip>
                              to {{ ta.user_name || ta.notification_email }}
                            </v-alert>
                          </v-card-text>
                          <v-card-text v-if="a.messages.length" class="pt-0 pl-12">
                            <v-alert
                              border="left"
                              colored-border
                              type="info"
                              dense
                              class="mb-0 body-2"
                              v-for="(ta, tai) in a.messages"
                              :key="'a2_' + a.document_activity_id + 'ma' + tai"
                            >
                              send MESSAGE
                              <v-chip small outlined label color="brown">{{
                                ta.type
                              }}</v-chip>
                            </v-alert>
                          </v-card-text>
                          <v-card-text v-if="a.comments.length" class="pt-0 pl-12">
                            <v-alert
                              border="left"
                              colored-border
                              type="info"
                              dense
                              class="mb-0 body-2"
                              v-for="(ta, tai) in a.comments"
                              :key="'a2_' + a.document_activity_id + 'ca' + tai"
                            >
                              add COMMENT
                              <v-chip small outlined label color="teal">{{
                                ta.subject
                              }}</v-chip>
                              <span> </span>
                              <q>{{ ta.comment }}</q>
                            </v-alert>
                          </v-card-text>
                          <v-card-text v-if="a.removed_comments.length" class="pt-0 pl-12">
                            <v-alert
                              border="left"
                              colored-border
                              type="warning"
                              dense
                              class="mb-0 body-2"
                              v-for="(ta, tai) in a.removed_comments"
                              :key="'a2_' + a.document_activity_id + 'ca' + tai"
                            >
                              removed COMMENT
                              <v-chip small outlined label color="teal">{{
                                ta.subject
                              }}</v-chip>
                              <span> </span>
                              <q>{{ ta.comment }}</q>
                            </v-alert>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </div>
                  </v-slide-y-transition>
                </template>
              </v-timeline>
              <v-list v-else-if="t.name === 'roles'">
                <v-list-group
                  v-for="r in roles"
                  :key="'r_' + r.role_user_id + '_' + r.lifecycle_role_id"
                  v-model="r.active"
                  prepend-icon="mdi-account-box-outline"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content :class="{'inactive': r.role_status === 'Inactive'}">
                      <v-list-item-title>{{ r.role_name }}
                        <v-tooltip right>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-icon v-if="r.role_status" v-on="{ ...tooltip }">warning</v-icon>
                          </template>
                          <span>{{ r.role_status}}</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-icon v-if="r.review_group" v-on="{ ...tooltip }">group</v-icon>
                          </template>
                          <span>Assigned via <b>{{ r.review_group }}</b> review group</span>
                        </v-tooltip>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ r.user_name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="a in r.activities"
                    :key="
                      'r_' +  + r.role_user_id + '_' + r.lifecyle_role_id + '_a' + a.document_activity_id
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        > {{ a.activity_description }} -
                        {{ a.activity_date }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
              <v-simple-table v-else-if="t.name === 'tasks'" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th><h3>Created</h3></th>
                      <th><h3>Type</h3></th>
                      <th><h3>Due</h3></th>
                      <th><h3>Status</h3></th>
                      <th><h3>Assigned To</h3></th>
                      <th><h3>Closed</h3></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="t in tasks" :key="'t_' + t.document_task_id">
                      <td>{{ t.created_date }}</td>
                      <td>{{ t.type }}</td>
                      <td>{{ t.due_date }}</td>
                      <td>
                        <v-chip
                          dense
                          small
                          class="status-chip"
                          label
                          :color="t.status_colour"
                          >{{ t.status }}</v-chip
                        >
                      </td>
                      <td>{{ t.assigned_user_name }}</td>
                      <td>{{ t.completed_date || t.completed_date }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table v-else-if="t.name === 'notifications'" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th><h3>Sent</h3></th>
                      <th><h3>Type</h3></th>
                      <th><h3>To</h3></th>
                      <th><h3>Email</h3></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="n in notifications"
                      :key="'n_' + n.document_notification_id"
                    >
                      <td>{{ n.notification_date }}</td>
                      <td>{{ n.type }}</td>
                      <td>{{ n.user_name }}</td>
                      <td>{{ n.notification_email }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table v-else-if="t.name === 'messages'" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th><h3>Sent</h3></th>
                      <th><h3>Type</h3></th>
                      <th><h3>Destination</h3></th>
                      <th><h3>Data</h3></th>
                      <th><h3>Error Message</h3></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="m in messages"
                      :key="'m_' + m.document_message_id"
                    >
                      <td>{{ m.message_date }}</td>
                      <td>{{ m.description }}</td>
                      <td>{{ m.destination }}</td>
                      <td>{{ m.data }}</td>
                      <td>{{ m.error_message }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table v-else-if="t.name === 'comments'" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th><h3>Added</h3></th>
                      <th><h3>Type</h3></th>
                      <th><h3>Comment</h3></th>
                      <th><h3>By</h3></th>
                      <th><h3>Status</h3></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="c in comments" :key="'c_' + c.document_comment_id">
                      <td>{{ c.created_date }}</td>
                      <td>{{ c.subject }}</td>
                      <td>{{ c.comment }}</td>
                      <td>{{ c.user_name }}</td>
                      <td><v-chip
                          small
                          class="status-chip"
                          label
                          :color="c.status === 'Removed' ? 'orange' : 'blue'"
                          >{{ c.status }}</v-chip
                        ></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table v-else-if="t.name === 'relatedDocs'" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th><h3>Job Title</h3></th>
                      <th><h3>Doc Type</h3></th>
                      <th><h3>Status</h3></th>
                      <th><h3>Created</h3></th>
                      <th><h3>Created By</h3></th>
                      <th><h3>Relationship</h3></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="d in relatedDocs" :key="'rd_' + d.id">
                      <td>
                        <v-checkbox
                          @click.stop
                          class="ma-0"
                          value
                          :input-value="compareDocs.includes(d.id)"
                          @change="toggleItemInList(d.id, compareDocs)"></v-checkbox>
                      </td>
                      <td>
                        {{ d.doc_name }}
                      </td>
                      <td>{{ d.tmpl_name }}</td>
                      <td><v-chip
                          small
                          class="status-chip"
                          label
                          :color="d.status_colour"
                          >{{ d.status_name }}</v-chip
                        ></td>
                      <td>{{ d.created_date }}</td>
                      <td>{{ d.created_by_user }}</td>
                      <td>{{ d.relationship }}</td>
                      <td style="min-width: 100px;">
                        <v-menu  offset-y bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              icon
                              v-bind="attrs"
                              v-on="on"
                              class="float-right"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item @click="viewDoc(d)">
                              <v-list-item-icon>
                                <v-icon>visibility</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                View
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="d.file_id" @click="nlpUtils.downloadFile(d.file_id)" >
                              <v-list-item-icon>
                                <v-icon>download</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Download
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="d.file_id && ($loginState.user.canDeleteAnyFile || d.can_delete == 1)" @click="deleteDatabaseDoc(d)" >
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Delete
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-card-text>
    </v-card>
    <v-dialog
        v-model="deleteFile.show"
        max-width="600px"
      >
        <v-card>
          <v-card-title class="justify-right">
            Confirm
            <v-spacer></v-spacer>
            <v-btn icon class="btn-background" @click="deleteFile.show = false"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="pb-0">
            <p>Permanently delete {{ deleteFile.file_name }} ?</p>
          </v-card-text>
          <v-card-actions class="px-6 pb-4">
            <v-btn color="primary" outlined @click="deleteFile.show = false">Cancel</v-btn>
            <v-spacer></v-spacer>
            
            <v-btn class="error" @click="confirmDelete"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="isLoading" />
  </v-dialog>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import DocumentVersionCompare from "@/components/audit/cDocumentVersionCompare";
import DocumentActivityDetails from "@/components/audit/cDocumentActivityDetails";
import utils from "@/common/utils.js";
import nlpUtils from "@/common/nlpUtils.js";
import { mapState } from "vuex";

export default {
  name: "DocumentActivities",
  components: {
    ResponseHandler,
    DocumentVersionCompare,
    DocumentActivityDetails,
  },
  props: {
    doc_id: null,
    open: null,
  },
  data: function () {
    return {
      show: false,
      tab: 0,
      reference: null,
      lifecycle_name: null,
      roles: [],
      activities: [],
      timeline: [],
      activityTypes: [],
      includedActivityTypes: [],
      activityUsers: [],
      includedActivityUsers: [],
      tasks: [],
      notifications: [],
      messages: [],
      comments: [],
      relatedDocs: [],
      isLoading: false,
      response: null,
      utils: utils,
      nlpUtils: nlpUtils,
      compareActivities: [],
      compareDocs: [],
      showCompare: false,
      compareHighlightChanges: true,
      compareHideCompareVersion: false,
      compareMultiDoc: false,
      deleteFile: {
        show: false,
        file_name: '',
        file_id: null
      }
    };
  },
  updated() {},
  watch: {
    open: {
      immediate: true,
      handler(val) {
        if (val) {
          this.show = true;
          this.getData();
        }
      },
    },
    filteredActivities() {
      const timeline = [];
      const activityColour = (a) => {
        if (a.new_status_colour) {
          return a.new_status_colour;
        } else
          switch(a.activity_type) {
            case "Edit":
              return "primary";
            default:
              return "cyan";
          }
      }
      const addGroup = (group) => {
        group.expandable = group.actions.length > 1
          || group.actions.some(a => a.doc_part_audit_id)
          || group.actions.some(a => a.doc_audit_id)
          || group.actions.some(a => a.doc_hierarchy_audit_id)
          || group.actions.some(a => a.tasksAffected.length)
          || group.actions.some(a => a.notifications.length)
          || group.actions.some(a => a.messages.length)
          || group.actions.some(a => a.comments.length);
        if (group.start_date === group.end_date) {
          group.date_range = group.start_date;
        } else if (group.start_date.substring(0, 11) === group.end_date.substring(0, 11)) {
          group.date_range = group.start_date.substring(0, 11);
        } else {
          group.date_range = `${group.start_date.substring(0, 11)} - ${group.end_date.substring(0, 11)}`;
        }

        if (group.actions.length === 1)
          group.actions[0].showDetails = true;

        timeline.push(group);
      }

      let group = null;
      this.filteredActivities.forEach(a => {
        if (group && (group.user_name !== a.user_name || a.activity_type !== group.activity_type)) {
          addGroup(group);
          group = null;
        }
        if (!group) {
          group = {
            document_activity_id: a.document_activity_id,
            activity_type: a.activity_type,
            user_name: a.user_name,
            start_date: a.activity_date,
            end_date: a.activity_date,
            expanded: false,
            actions: [],
            new_status: a.new_status,
            new_status_colour: a.new_status_colour,
            colour: activityColour(a),
            score: a.score,
            scoreColour: a.scoreColour
          };
        }
        group.start_date = a.activity_date;
        a.showDetails = a.doc_audit_id || a.doc_hierarchy_audit_id;
        group.actions.push(a);
      });
      if (group)
        addGroup(group);
      this.timeline = timeline;
    }
  },
  computed: {
    tabs() {
      const tabs = [
        { name: "actions", description: `Actions (${this.activities.length})` },
        { name: "roles", description: `Roles (${this.roles.length})` }
      ];
      if (this.tasks.length)
        tabs.push({ name: "tasks", description: `Tasks (${this.tasks.length})` })
      if (this.notifications.length)
        tabs.push({ name: "notifications", description: `Notifications (${this.notifications.length})` })
      if (this.messages.length)
        tabs.push({ name: "messages", description: `Messages (${this.messages.length})` })
      if (this.comments.length)
        tabs.push({ name: "comments", description: `Comments (${this.comments.length})` })
      if (this.relatedDocs.length)
        tabs.push({ name: "relatedDocs", description: `Related Docs (${this.relatedDocs.length})` })
      return tabs;
    },
    filteredActivities() {
      return this.activities
        .filter(a => this.includedActivityTypes.includes(a.activity_type))
        .filter(a => this.includedActivityUsers.includes(a.user_name));
    },
    compareList() {
      let compareList = [];
      const currentTab = this.tabs[this.tab]?.name
      if (currentTab === 'actions') {
        compareList = this.compareActivities.map(a =>{
          return {
            doc_id: this.doc_id,
            activity_id: a,
          }
        });
      } else if (currentTab === 'relatedDocs') {
        compareList = this.relatedDocs
          .filter(d => this.compareDocs.includes(d.id))
          .map(d => {
            return {
              doc_id: d.doc_id,
              file_id: d.file_id,
              row_id: d.row_id,
              doc_name: d.doc_name,
              created_date: d.created_date,
              created_by: d.created_by,
              status: d.status_name,
              status_colour: d.status_colour
            }
          });
      }
      return compareList;
    },
    ...mapState({
      config: (state) => state.settings.config,
    }),
    showScores() {
      return this.config.settings?.find(s => s.setting === "audit_trail_show_scores")?.value === 'true';
    } 
  },
  methods: {
    close() {
      this.show = false;
      this.roles.splice();
      this.activities.splice();
      this.tasks.splice();
      this.notifications.splice();
      this.messages.splice();
      this.comments.splice();
      this.relatedDocs.splice();
      this.lifecycle_name = null;
      this.$emit("closed");
    },
    getData() {
      this.isLoading = true;
      axios
        .get("workflow/documentactivity/" + this.doc_id)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.reference = resp.data.Data.reference;
            this.lifecycle_name = resp.data.Data.lifecycle_name;
            this.roles = resp.data.Data.roles;
            this.activities = resp.data.Data.activities.map(a => {
              a.hasDetails = a.doc_part_audit_id || a.doc_audit_id || a.doc_hierarchy_audit_id;
              a.showDetails = false;
              return a;
            });
            this.activityTypes = resp.data.Data.activities
              .map(a => a.activity_type)
              .filter((value, index, array) => array.indexOf(value) === index)
              .sort();
            //show only the "interesting" activity types by default
            this.includedActivityTypes = resp.data.Data.activities
              .filter(a => a.doc_part_audit_id
                || a.doc_audit_id
                || a.doc_hierarchy_audit_id
                || a.new_status
                || a.tasksAffected.length
                || a.notifications.length
                || a.comments.length
                || a.messages.length
                || a.activity_type.toLowerCase().includes("create"))
              .map(a => a.activity_type)
              .filter((value, index, array) => array.indexOf(value) === index)
              .sort();
            this.activityUsers = resp.data.Data.activities
              .map(a => a.user_name)
              .filter((value, index, array) => array.indexOf(value) === index)
              .sort();
            this.includedActivityUsers = [...this.activityUsers];
            this.tasks = resp.data.Data.tasks;
            this.notifications = resp.data.Data.notifications;
            this.messages = resp.data.Data.messages;
            this.comments = resp.data.Data.comments;
            this.relatedDocs = resp.data.Data.relatedDocs.map(d => {
              d.id = d.file_id ? `f${d.file_id}` : `d${d.doc_id}`;
              return d;
            });
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    toggleItemInList(ref, list) {
      let itemIdx = list.indexOf(ref);
      itemIdx === -1
         ? list.push(ref)
         : list.splice(itemIdx, 1);
    },
    doCompare() {
      this.compareHideCompareVersion = false;
      this.highlightChanges = true;
      this.showCompare = true;
      this.compareMultiDoc = this.tabs[this.tab]?.name === "relatedDocs";
    },
    cancelCompare() {
      this.compareActivities.splice(0);
      this.compareDocs.splice(0);
      this.showCompare = false;
    },
    viewVersion(v) {
      this.compareActivities.splice(0);
      this.compareActivities.push(v.document_activity_id);
      const prevVersion = this.timeline[this.timeline.indexOf(v) + 1];
      if (prevVersion)
        this.compareActivities.push(prevVersion.document_activity_id);

      this.compareHideCompareVersion = true;
      this.highlightChanges = true;
      this.showCompare = true;
      this.compareMultiDoc = false;
    },
    viewDoc(d) {
      this.compareDocs.splice(0);
      this.compareDocs.push(d.id);
      this.doCompare();
    },
    deleteDatabaseDoc(item) {
      this.deleteFile.file_name = item.file_name;
      this.deleteFile.file_id = item.file_id;
      this.deleteFile.show = true;
    },
    confirmDelete() {
      if (!this.deleteFile.file_id)
        return;
      
      this.deleteFile.processing = true;
      axios
        .post("file/delete", [this.deleteFile.file_id])
        .then((resp) => {
          if(resp.data.Status == "OK") {
            this.$store.dispatch("files/reload");
            this.relatedDocs = this.relatedDocs.filter(f => f.file_id !== this.deleteFile.file_id);
          }
          this.deleteFile.processing = false;
          this.deleteFile.show = false;
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.deleteFile.processing = false; 
        });
    },
    exportToCSV() {
      
      let items, headers;
      switch (this.tabs[this.tab].name) {
        case 'actions':
          items = this.activities;
          headers = [
            { text: 'Date', value: 'activity_date' },
            { text: 'Description', value: 'activity_description' },
            { text: 'User', value: 'user_name' },
            { text: 'Comments', value: 'comments'},
            { text: 'Tasks', value: 'tasksAffected'},
            { text: 'Notifications', value: 'notifications'},
            { text: 'Messages', value: 'messages'},
          ];
          break;
        case 'roles':
          items = this.roles;
          headers = [
            { text: 'Role', value: 'role_name' },
            { text: 'User', value: 'user_name' },
            { text: 'Status', value: 'role_status' },
          ];
          break;
        case 'tasks':
          items = this.tasks;
          headers = [
            { text: 'Created', value: 'created_date' },
            { text: 'Type', value: 'type' },
            { text: 'Due', value: 'due_date' },
            { text: 'Status', value: 'status' },
            { text: 'Assigned User', value: 'assigned_user_name' },
            { text: 'Closed', value: 'completed_date' },
          ];
          break;
        case 'notifications':
          items = this.notifications;
          headers = [
            { text: 'Sent', value: 'notification_date' },
            { text: 'Type', value: 'type' },
            { text: 'To', value: 'user_name' },
            { text: 'Email', value: 'notification_email' },
          ];
          break;
        case 'messages':
          items = this.messages;
          headers = [
            { text: 'Sent', value: 'message_date' },
            { text: 'Type', value: 'description' },
            { text: 'Destination', value: 'destination' },
            { text: 'Data', value: 'data' },
            { text: 'Error Message', value: 'error_message' },
          ];
          break;
        case 'comments':
          items = this.comments;
          headers = [
            { text: 'Added', value: 'created_date' },
            { text: 'Type', value: 'subject' },
            { text: 'Comment', value: 'comment' },
            { text: 'By', value: 'user_name' },
          ];
          break;
        case 'relatedDocs':
          items = this.relatedDocs;
          headers = [
            { text: 'Job Title', value: 'doc_name' },
            { text: 'Doc Type', value: 'tmpl_name' },
            { text: 'Status', value: 'status_name' },
            { text: 'Created', value: 'created_date' },
            { text: 'Created By', value: 'created_by' },
            { text: 'Relationship', value: 'relationship' },
          ];
          break;
      }
      let data = headers.map((h) => '"' + h.text + '"').join(",") + "\n";
      items.forEach((i) => {
        data +=
          headers 
            .map((h) => {
                if (h.value === "comments")
                  return utils.csvEscape(i.comments.map(c => `${c.subject} - ${c.comment}`).join(";"));
                else if (h.value === "tasksAffected")
                  return utils.csvEscape(i.tasksAffected.map(ta => `${ta.type}, due ${ta.due_date} ${ta.assigned_user_name ? "Assigned to: " + ta.assigned_user_name: "UNNASIGNED"}`).join(";"));
                else if (h.value === "notifications")
                  return utils.csvEscape(i.notifications.map(n => `${n.type} to ${n.user_name}`).join(";"));
                else if (h.value === "messages")
                  return utils.csvEscape(i.messages.map(m => `${m.type}`).join(";"));
                else
                  return utils.csvEscape(utils.removeTags(i[h.value]));
            })
            .join(",")
            + "\n";
      });
      utils.downloadFile(data, `${this.reference}-${this.tabs[this.tab].name}.csv`, "text/csv;encoding:utf-8");
    },
    openDocument(item) {
      this.close();
      this.$emit("openDocument", item);
    },
  },
};
</script>
<style scoped lang="scss">
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.activityDetails {
  cursor: pointer;
  background-color: transparent !important;
  .v-alert {
    margin: 10px 10%;
  }
}

.toggleExpand {
    transition: transform .3s ease-in-out !important;  
}

.toggleExpand.expanded {
    transform: rotate(-180deg);
}

.inactive {
  opacity: 0.5;
}

::v-deep {
  .v-timeline--dense .v-timeline-item__opposite {
    display: inline-block;
    text-align: right;
    flex: none;
    width: 20%;
  }

  .v-timeline--dense:before {
    left: calc(20% + 56px) !important;
    width: 2px !important;
  }
}

</style>
